import { Link as RemixLink } from "@remix-run/react";
import type { RemixLinkProps } from "@remix-run/react/dist/components";
import { Menu, X } from "lucide-react";
import { useState } from "react";
import { Button } from "~/components/ui/button";
import { cn } from "~/lib/utils";

function Link({ children, to, ...props }: RemixLinkProps) {
    return (
        <RemixLink
            to={to}
            {...props}
            className={cn(
                "font-medium text-sm transition-colors duration-200 hover:text-purple-600",
                props.className,
            )}
        >
            {children}
        </RemixLink>
    );
}

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <header className="sticky top-0 z-50 flex h-14 items-center justify-between bg-white px-4 shadow-sm backdrop-blur md:supports-[backdrop-filter]:bg-white/60 lg:px-6">
            <Link
                className="flex items-center justify-center"
                to="/"
                onClick={() => setIsMenuOpen(false)}
            >
                <img src="/logo.svg" alt="Logo" className="h-6" />
            </Link>
            <nav className="hidden items-center gap-4 sm:gap-6 md:flex">
                <Link to="/#features">Features</Link>
                <Link to="/#how-it-works">How it works</Link>
                <Link to="/#pricing">Pricing</Link>
                <Link to="/knowledgebase">Knowledgebase</Link>
                <Link to="/blog">Blog</Link>
                <Link to="/contact">Contact</Link>
                <Button
                    variant="outline"
                    className="transition-colors duration-200 hover:bg-purple-50 plausible-event-name=LogIn"
                    asChild
                >
                    <Link to="/auth/login">Log in</Link>
                </Button>
                <Button
                    className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 transition-colors duration-200 hover:from-pink-700 hover:via-purple-800 hover:to-blue-800 plausible-event-name=SignUp"
                    asChild
                >
                    <Link to="/auth/signup">Sign up</Link>
                </Button>
            </nav>
            <button
                className="text-gray-600 transition-colors duration-200 hover:text-purple-600 md:hidden"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
            {isMenuOpen && (
                <div className="absolute top-14 right-0 left-0 bg-white p-4 shadow-md backdrop-blur md:hidden md:supports-[backdrop-filter]:bg-white/60">
                    <nav className="flex flex-col gap-4">
                        <Link onClick={() => setIsMenuOpen(false)} to="/#features">
                            Features
                        </Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="/#how-it-works">
                            How it works
                        </Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="/#pricing">
                            Pricing
                        </Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="/contact">
                            Contact
                        </Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="/knowledgebase">
                            Knowledgebase
                        </Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="/blog">
                            Blog
                        </Link>
                        <Button
                            variant="outline"
                            className="w-full transition-colors duration-200 hover:bg-purple-50 plausible-event-name=LogIn"
                            asChild
                        >
                            <Link to="/auth/login">Log in</Link>
                        </Button>
                        <Button
                            className="w-full bg-purple-600 transition-colors duration-200 hover:bg-purple-700 plausible-event-name=SignUp"
                            asChild
                        >
                            <Link to="/auth/signup">Sign up</Link>
                        </Button>
                    </nav>
                </div>
            )}
        </header>
    );
}
