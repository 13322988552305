import { Link } from "@remix-run/react";
import SocialLinks from "./social-links";

export default function Footer() {
    return (
        <footer className="w-full bg-white py-12">
            <div className="container mx-auto px-4">
                <div className="mb-8 flex flex-col items-center">
                    <Link to="/" className="mb-4 flex items-center justify-center">
                        <img src="/logo.svg" alt="Logo" className="h-6" />
                    </Link>
                    <p className="text-gray-500 text-sm">
                        Copyright © {new Date().getFullYear()} TailoredCV.ai. All rights reserved.
                    </p>
                </div>
                <div className="mb-8 grid grid-cols-1 gap-8 md:grid-cols-3">
                    <div>
                        <h3 className="mb-2 font-semibold">Product</h3>
                        <ul className="space-y-2">
                            <li>
                                <Link
                                    to="/#pricing"
                                    className="text-gray-500 text-sm hover:text-purple-600"
                                >
                                    Pricing
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/knowledgebase"
                                    className="text-gray-500 text-sm hover:text-purple-600"
                                >
                                    Knowledgebase
                                </Link>
                            </li>
                            <li className="mb-4">
                                <Link
                                    to="/blog"
                                    className="text-gray-500 text-sm hover:text-purple-600"
                                >
                                    Blog
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/contact"
                                    className="text-gray-500 text-sm hover:text-purple-600"
                                >
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="mb-2 font-semibold">Legal</h3>
                        <ul className="space-y-2">
                            <li>
                                <Link
                                    to="/legal/privacy-policy"
                                    className="text-gray-500 text-sm hover:text-purple-600"
                                >
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/legal/terms-of-service"
                                    className="text-gray-500 text-sm hover:text-purple-600"
                                >
                                    Terms of Service
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/legal/refund-policy"
                                    className="text-gray-500 text-sm hover:text-purple-600"
                                >
                                    Refund Policy
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="mb-2 font-semibold">Connect</h3>
                        <div className="flex space-x-4">
                            <SocialLinks />
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <p className="inset-x-0 bg-gradient-to-b from-neutral-50 to-neutral-200 bg-clip-text text-center font-bold text-5xl text-transparent md:text-8xl lg:text-[12rem]">
                        tailoredcv.ai
                    </p>
                </div>
            </div>
        </footer>
    );
}
