import { Outlet } from "@remix-run/react";
import { Toaster } from "~/components/ui/sonner";
import Footer from "~/components/website/footer";
import Header from "~/components/website/header";

export default function Component() {
    return (
        <div className="flex min-h-[100dvh] flex-col">
            <Header />
            <Outlet />
            <Footer />
            <Toaster />
        </div>
    );
}
